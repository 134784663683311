html {
  color: #AEA037;
  background-color: #282625;
}

img {
  height: 192px;
  padding: 10px 5px 0 5px;
}

a:link {
  color: #AEA037;
  text-decoration: none;
}

a:visited {
  color: #AD7D2D;
  text-decoration: none;
}

a:hover {
  color: #AEA037;
  text-decoration: none;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  text-align: center;
}

.title {
  width: 30%;
  height: 30%;
  display: block;
  margin: 0 auto;
}

.title-main {
  width: 40%;
  height: 40%;
}

.date {
  text-align: center;
  color: gray;
}

.card {
  align-items: center;
  white-space: nowrap;
}

.text {
  margin-top: 0px;
}

.tracks {
  color: gray;
}

.button {
  position: fixed;
  top: 0;
  background-color: #AEA037;
  color: white;
  border-radius: 4px;
  width: 85px;
}

.mode {
  background: none;
  border: none;
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  margin-left: 5px;
}

.floating {
  position: fixed;
  margin-top: 5px;
}